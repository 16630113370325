<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Yêu cầu khóa tài khoản</h6>
        </template>
        <b-row>
          <b-col class="col-md-3">
            <b-form-group label="Họ tên">
              <b-form-input
                  v-model="filter.name"
                  placeholder="Họ tên"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col-md-3">
            <b-form-group label="Số điện thoại">
              <b-form-input
                  v-model="filter.phone"
                  placeholder="Số điện thoại"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col-md-3">
            <b-form-group label="Số CCCD">
              <b-form-input
                  v-model="filter.id_card_no"
                  placeholder="Số CCCD"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col-md-3">
            <b-form-group label="Trạng thái">
              <b-form-select :options="status" v-model="filter.status"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-md-6">
            <b-form-group label="Lý do khóa ví">
              <b-form-input
                  v-model="filter.reason"
                  placeholder="Lý do khóa ví"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col-md-3">
            <b-form-group label="Ngày bắt đầu">
              <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="filter.date_from"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col-md-3">
            <b-form-group label="Ngày kết thúc">
              <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="filter.date_to"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col">
            <div class="text-right">
              <b-button variant="outline-info" @click="search">Tìm kiếm</b-button>
            </div>
          </b-col>
        </b-row>

        <hr />
        <div v-if="paginate.total > 0"><span>Tổng số: </span><strong>{{ numberFormat(paginate.total) }}</strong></div>
        <b-table
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
        >
          <template #cell(index)="data">
            {{ (currentPage - 1) * 25 + (data.index + 1) }}
          </template>

          <template #cell(status)="data">
            <span :class="`rounded p-1 bg-${convertStatusLabel(data.item.status).class }`">{{ convertStatusLabel(data.item.status).label }}</span>
          </template>

          <template #cell(balance)="data">
            {{ numberFormat(parseInt(data.item.balance)) }}
          </template>

          <template #cell(options)="data" class="text-right">
            <b-button-group size="sm">
              <b-button class="btn btn-sm btn-outline-success btn-primary" @click="detail(data.item.id)">Chi tiết</b-button>
            </b-button-group>
          </template>
        </b-table>
        <div class="mt-3">
          <b-pagination-nav
            v-model="currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="paginate.totalPage"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>
<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import router from "@/router";

const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
  mixins: [Common],
  components: {},
  data() {
    return {
      items: [],
      item: {
        name: null,
        time_frame: null,
        message: null,
        status: null,
        params: {},
      },
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      filter: {
        name: null,
        phone: null,
        reason: null,
        id_card_no: null,
        status: null,
        date_from: this.getFirstDayOfMonth(),
        date_to: this.getCurrentDay(),
      },
      fields: [
        { index: "STT" },
        { name: "Họ tên" },
        { phone: "Số điện thoại" },
        { email: "Email" },
        { id_card_no: "Số CCCD" },
        { balance: "Số dư ví (VND)" },
        { reason: "Lý do" },
        { created_at: "Ngày yêu cầu" },
        { approved_at: "Ngày xử lý" },
        { approved_by: "Người duyệt" },
        { status: "Trạng thái" },
        { options: "Hành động" },
      ],
      status: [
        { value: "", text: "Tất cả" },
        { value: "PENDING", text: "Chờ duyệt" },
        { value: "REJECTED", text: "Từ chối" },
        { value: "APPROVED", text: "Đã duyệt" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Yêu cầu khóa tài khoản", route: "tools/user/request-lock" },
    ]);
  },
  methods: {
    convert(params) {
      for (let key in params) {
        if (params[key] == "" || params[key] == null) {
          delete params[key];
        }
      }
      return params;
    },
    search() {
      this.filter.page = 1;
      router.push({ path: "/tools/user/request-lock", query: this.filter }).catch(() => {});

      if (this.currentPage !== 1) {
        this.currentPage = 1;
        return;
      }

      this.getList(this.currentPage);
    },
    getList(page = 1) {
      this.items = [];
      let params = { ...this.filter };
      params = this.convert(params);
      params.page = page;
      this.$bus.$emit("show-loading", true);
      CmsRepository.listRequestLockAccount(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            return;
          }

          this.items = response.data.data.data.data;
          this.paginate.total = response.data.data.data.total;
          this.paginate.totalPage = response.data.data.data.last_page;
          this.paginate.currentPage = response.data.data.data.current_page;
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    detail(id) {
      window.location.href = "/#/tools/user/request-lock/detail?id=" + id;
    },
    convertStatusLabel(status) {
      let arrStatus = {
        PENDING: { class: "warning", label: "Chờ phê duyệt" },
        REJECTED: { class: "danger", label: "Từ chối" },
        APPROVED: { class: "success", label: "Đã duyệt" },
      };

      if (arrStatus[status] === undefined) {
        return { class: "warning", label: "Chờ phê duyệt" };
      }

      return arrStatus[status];
    }
  },
  created() {
    this.getList();
  },
  watch: {
    currentPage() {
      this.getList(this.currentPage);
    },
  },
};
</script>
